<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.donate.title")}}</v-card-title>

        <v-card-text v-html="$t('support.donate.text')"></v-card-text>

        <v-expansion-panels class="donation-panels" popout focusable>
            <v-expansion-panel v-if="$variables.v.donation.paypal.enabled">
                <v-expansion-panel-header>
                    <div class="donation-image-container">
                        <img :src="$variables.v.donation.imagefolder + $variables.v.donation.paypal.image"
                             class="donation-image donation-image-paypal"
                             :alt="$t('support.donate.paypal.title')"
                             loading="lazy">
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-5">
                    <span v-html="$t('support.donate.paypal.text')"></span> {{$variables.v.donation.paypal.address}}
                </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header v-if="$variables.v.donation.bitcoin.enabled">
                    <div class="donation-image-container">
                        <img :src="$variables.v.donation.imagefolder + $variables.v.donation.bitcoin.image"
                             class="donation-image donation-image-bitcoin"
                             :alt="$t('support.donate.bitcoin.title')"
                             loading="lazy">
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-5">
                    <span v-html="$t('support.donate.bitcoin.text')"></span> {{$variables.v.donation.bitcoin.address}}

                    <div>
                        <img :src="$variables.v.donation.imagefolder + $variables.v.donation.bitcoin.addressimg"
                             alt=""
                             loading="lazy">
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header v-if="$variables.v.donation.ethereum.enabled">
                    <div class="donation-image-container">
                        <img :src="$variables.v.donation.imagefolder + $variables.v.donation.ethereum.image"
                             class="donation-image donation-image-ethereum"
                             :alt="$t('support.donate.ethereum.title')"
                             loading="lazy">
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-5">
                    <span v-html="$t('support.donate.ethereum.text')"></span> {{$variables.v.donation.ethereum.address}}

                    <div>
                        <img :src="$variables.v.donation.imagefolder + $variables.v.donation.ethereum.addressimg"
                             alt=""
                             loading="lazy">
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-donate')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>